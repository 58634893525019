/* eslint-disable no-unreachable */
import { login, userList, getReportsList } from '../crud/axios.crud';




export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const IS_LOADING = 'IS_LOADING';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const USERLIST_RESPONSE = 'USERLIST_RESPONSE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REPORT_LIST_RESPONSE = 'REPORT_LIST_RESPONSE';



export function isLoading(isLoading = false) {
    return {
        type: IS_LOADING,
        isLoading: isLoading,
    };
}

export function loginResponse(json) {
    return {
        type: LOGIN_RESPONSE,
        json: json,
    };
}

export function loginError(loginError = false) {
    return {
        type: LOGIN_ERROR,
        loginError: loginError,
    };
}

export function userListResponse(json) {
    return {
        type: USERLIST_RESPONSE,
        json: json,
    };
}


export function reportListResponse(json) {
    return {
        type: REPORT_LIST_RESPONSE,
        json: json,
    };
}

export function loginRequest({ email, password }, props) {
    return async (dispatch) => {
        try {
            dispatch(isLoading(true));
            const { data } = await login({ email, password });
            const userData = { user: data.user, tokens: data.tokens };
            localStorage.setItem("UserData", JSON.stringify(userData));
            dispatch(isLoading(false));
            dispatch(loginResponse(userData));
            props.history.push('/dashboard/default');
        } catch (error) {
            dispatch(loginError(true));
            dispatch(isLoading(false));
        }
    };

}



export function userListRequest(props) {
    return async (dispatch) => {
        dispatch(isLoading(true));
        try {
            const { data } = await userList();
            dispatch(isLoading(false));
            dispatch(userListResponse(data.results));
        } catch (error) {
            dispatch(isLoading(false));
            dispatch(userListResponse([]));
            if (error.response.status === 401) {
                localStorage.clear();
                props.history.push('/auth/signin-1');
            }
        }
    }
}


export function reportListRequest(props) {
    return (dispatch) => {
        dispatch(isLoading(true));
        getReportsList().then(({ data }) => {
            dispatch(reportListResponse(data.results));
            dispatch(isLoading(false));
        }).catch((err) => {
            dispatch(isLoading(false));
            dispatch(reportListResponse([]));
            if (err?.response?.status === 401) {
                localStorage.clear();
                props.history.push('/auth/signin-1');
            }
        })
    }
}