import axios from "axios";
import config from '../config';
import * as actionTypes from '../store/actions';
const caseLimit = "5000"
const headers = {
    'Content-Type': 'application/json'
}

function updateUserState(data) {
    return (dispatch) => {
        dispatch(actionTypes.loginResponse(data));
    }
}

export function loggedInuserToken() {
    try {
        const data = JSON.parse(localStorage.getItem("UserData"));
        updateUserState(data);
        return data?.tokens?.access?.token;

    } catch (error) {

    }
}

export function loggedInuserRefreshToken() {
    try {
        const data = JSON.parse(localStorage.getItem("UserData"));
        updateUserState(data);
        return data?.tokens?.access?.token;

    } catch (error) {

    }
}


export function login(data) {
    return axios.post(`${config.serverUrl}v1/auth/login`, data, headers);
}

export function userList() {
    let token = loggedInuserToken();
    return axios.get(`${config.serverUrl}v1/users`, {
        headers: { 'Authorization': `bearer ${token} ` }
    })
}

export function createUser(dataObj) {
    let token = loggedInuserToken();
    return axios.post(`${config.serverUrl}v1/users`, dataObj, {
        headers: { 'Authorization': `bearer ${token}` }
    });
}


export function updateUser(dataObj, id) {
    let token = loggedInuserToken();
    return axios.patch(`${config.serverUrl}v1/users/${id}`, dataObj, {
        headers: { 'Authorization': `bearer ${token}` }
    });
}

export function deleteUser(id) {
    let token = loggedInuserToken();
    return axios.delete(`${config.serverUrl}v1/users/${id}`, {
        headers: {
            'Authorization': `bearer ${token} `
        }
    });
}


export function createCase(data) {
    let token = loggedInuserToken();
    return axios.post(`${config.serverUrl}v1/cases`, data, {
        headers: {
            'Authorization': `bearer ${token}`,
        }
    })
}


export function getReportsList() {
    let token = loggedInuserToken();
    return axios.get(`${config.serverUrl}v1/cases?limit=${caseLimit}`, {
        headers: {
            'Authorization': `bearer ${token}`,
        }
    })
}

export function updateReport(dataObj, id) {
    let token = loggedInuserToken();
    return axios.patch(`${config.serverUrl}v1/cases/${id ? id : dataObj.id}`, dataObj, {
        headers: {
            'Authorization': `bearer ${token}`,
        }
    })
}

export function logout() {
    const data = {
        refreshToken: loggedInuserRefreshToken()
    };
    const token = loggedInuserToken();
    return axios.post(`${config.serverUrl}v1/auth/logout`, data, {
        headers: {
            'Authorization': `bearer ${token}`,
        }
    })
}

